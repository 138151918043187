'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';

import Wall from './Wall';
import Toggles from './Toggles';
import Menu from './Menu';

ns.docReady.then(() => {
	Menu.init();

	var searchBtn = $('.search-btn');
	var searchBar = $('.search-bar');
	var searchInput = $('input[name=search]');
	var searchBarCloseBtn = searchBar.find('.close');
	searchBtn.add(searchBarCloseBtn).on('click.poly', ()=>{
		searchBar.toggleClass('opened');
		searchInput.focus();
	});

  $('.share a').on('click.fe', function(){
    var el = $(this);
    var c = el.attr('class');
    switch (c) {
      case 'icon-facebook':
        var url = 'http://www.facebook.com/sharer.php?u='+encodeURI(el.attr('data-url'));
        break;
      case 'icon-twitter':
        var url = 'http://twitter.com/share?text='+encodeURI(el.attr('data-text'))+'&url='+encodeURI(el.attr('data-url'));
        break;
      case 'icon-gplus':
        var url = 'https://plus.google.com/share?url='+encodeURI(el.attr('data-url'));
        break;
    }
    window.open(url, 'sharer', 'toolbar=0,status=0,width=520,height=350');

    return false;
  });

	var homeSlider = $('body.home .masthead');
	homeSlider.slick({
		slide: '.slide',
		fade: true,
		autoplay: true,
		autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false,
    dots: true
	});

  var mediaQuery = window.matchMedia("(max-width: 991px)");
  homeSlider.find('.bg').each(function() {
    var slide = $(this);
    if (mediaQuery.matches) {
      slide.css('background-image', 'url(' + slide.data('image-mobile') + ')');
    }
    else {
      slide.css('background-image', 'url(' + slide.data('image-desktop') + ')');
    }
  });
});
