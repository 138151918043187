'format es6';
'use strict';

import $ from 'jquery';
import ns from './NameSpace';

var toggles = $('.toggle-tab');

if(toggles.length > 0) {
	toggles.find('.title').on('click.poly', (e) => {
		var toggle = $(e.currentTarget).parent('.toggle-tab');

		toggle.toggleClass('active');
		toggle.find('.content').slideToggle();
	});

  var hash = window.location.hash;
  if (hash != "") {
    if ($(hash).hasClass("title")) {
      $(hash).parent('.toggle-tab').addClass('active');
      $(hash).next(".content").show();
    }
    else {
      $(hash).closest(".content").addClass('active').show();
    }
  }
}
