'format es6';
'use strict';

import $ from 'jquery';
import ns from './NameSpace';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

var wall = $('.wall');

if(wall.length > 0) {
	new imagesLoaded(wall[0], () => {
		var iso = new Isotope( wall.find('.grid')[0], {
			itemSelector: '.wall-item',
			masonry: {
				columnWidth: '.grid-sizer'
			}
		});

		var btns = wall.find('.wall-filters > div');
		var more = wall.find('.load-more');

		var filter = '.wall-item';
		var number = 8;

		iso.arrange({
			filter: filter + ':lt(' + number + ')'
		});

		number >= $(filter).length ? more.hide() : more.show();

		btns.on('click.wall', (e)=>{
			var btn = $(e.currentTarget);
			btns.removeClass('active');
			btn.addClass('active');

			filter = btn.data('filter');
			iso.arrange({
				filter: filter + ':lt(' + number + ')'
			});

			number >= $(filter).length ? more.hide() : more.show();
		});

		more.on('click.more', (e)=>{
			number = number + 8;
			iso.arrange({
				filter: filter + ':lt(' + number + ')'
			});

      number >= $(filter).length ? more.hide() : more.show();
		});
	});
}
